import React, { useState } from "react";
import { Image, Text } from "@sitecore-jss/sitecore-jss-react";
export default function Compatibility(props) {
  const { fields } = props;
  const [open, setopen] = useState(false);
  const [currentLanguage, setcurrentLanguage] = useState("English");

  const languageHandler = (langauge) => {
    setcurrentLanguage(langauge);
    setopen(false);
  };
  return (
    <header className="global-header-2">
      <div className="l-padding header-container">
        <div className="logobox">
          <a href={(fields.logolink && fields.logolink.value.href) || "/"}>
            <Image field={fields.Logo} />
          </a>
        </div>
        {fields.languageSelector.length > 0 && (
          <div className="languageBox">
            <li
              className={`contact langauge-dropdown ${open ? "is-open" : ""}`}
            >
              <button
                className="dropdown-toggle"
                type="button"
                id="dropdownCountryMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-label="Select a language from the dropdown"
                onClick={() => setopen(!open)}
              >
                <span
                  id="nav-region-dropdown__cta"
                  className="langauge-dropdown__cta"
                  aria-hidden="true"
                >
                  {currentLanguage}
                </span>
              </button>

              <div
                className={`dropdown-menu ${open ? "open" : ""}`}
                aria-labelledby="dropdownCountryMenuButton"
              >
                <div className="mobile-overlay" />
                <div className="dropdown-items">
                  <button
                    type="button"
                    onClick={() => setopen(false)}
                    className="dropdown-item item-country close"
                  >
                    <span className="dropdown-item-title">Language</span>
                  </button>
                  <button type="button" className="dropdown-item item-country">
                    <div className="dropdownBox">
                      {fields.languageSelector.map((languages) => (
                        <span
                          role="link"
                          aria-labelledby="Select a language"
                          key={languages.id}
                          onClick={() =>
                            languageHandler(languages.fields.text.value)
                          }
                          onKeyDown={() =>
                            languageHandler(languages.fields.text.value)
                          }
                          className={`dropdown-item-title ${
                            languages.fields.text.value === currentLanguage
                              ? "selected"
                              : ""
                          }`}
                        >
                          <Text field={languages.fields.text} />
                        </span>
                      ))}
                    </div>
                  </button>
                </div>
              </div>
            </li>
          </div>
        )}
      </div>
    </header>
  );
}
